import React, { useState } from 'react';
import Calendar from 'react-calendar'; // Stelle sicher, dass die React-Calendar-Bibliothek installiert ist
import 'react-calendar/dist/Calendar.css'; // Standard-Styling für den Kalender
import './App.scss';

function Kontakt() {
  const [selectedDate, setSelectedDate] = useState(null); // Gewähltes Datum
  const [emailSent, setEmailSent] = useState(false); // Status für E-Mail-Versand
  const [errorMessage, setErrorMessage] = useState(null); // Status für Fehlermeldungen

  // Funktion zum Senden der Kalendereinladung
  const sendCalendarInvite = async () => {
    if (!selectedDate) {
      setErrorMessage('Bitte wählen Sie ein Datum aus.');
      return;
    }

    const startDate = selectedDate.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z'; // Startdatum
    const endDate = new Date(selectedDate.getTime() + 60 * 60 * 1000).toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z'; // Enddatum nach 1 Stunde
    const eventId = new Date().getTime(); // Einzigartige Event-ID
    const location = 'Kosmetikstudio Oberburg';
    const sequence = 0; // Initiale Version der Einladung

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send-calendar-invite`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          // to: 'kosmetikoberburg@gmail.com', // Empfängeradresse
          to: 'kosmetikoberburg@gmail.com', // Empfängeradresse
          subject: 'Kosmetiktermin Bestätigung',
          startDate: startDate, // Startdatum für iCal
          endDate: endDate, // Enddatum für iCal
          eventId: eventId, // Einzigartige Event-ID
          location: location, // Standort für den Termin
          sequence: sequence, // Versionsnummer der Einladung
        }),
      });

      if (response.ok) {
        setEmailSent(true);
        setErrorMessage(null);
      } else {
        let errorData;
        try {
          errorData = await response.text();
        } catch (e) {
          errorData = 'Unbekannter Fehler';
        }
        console.error('Fehler beim Senden der E-Mail:', errorData);
        setErrorMessage('Fehler beim Senden der E-Mail: ' + errorData + '. Bitte überprüfen Sie die Daten und versuchen Sie es erneut.');
      }
    } catch (error) {
      console.error('Fehler:', error);
      setErrorMessage('Fehler: ' + error.message);
    }
  };

  return (
    <div className="section">
      <h1>Termin</h1>

      {/* Bild als anklickbares Objekt mit dem WhatsApp-Link */}
      <a href="https://wa.me/qr/PSREAP4AHMBTG1" target="_blank" rel="noopener noreferrer">
        <img src="/img/qr.png" alt="Contact" style={{ cursor: 'pointer' }} />
      </a>

      <p>Scannen Sie den angezeigten QR-Code oder klicken Sie darauf, um mich direkt über WhatsApp hinzuzufügen und einen Termin nach Vereinbarung zu buchen.</p>
      <p>Oder rufen Sie an </p>
      <a href="tel:+41797116662" style={{ textDecoration: 'none', color: 'inherit' }}>
        <h2>+41 79 711 66 62</h2>
      </a>
      <p>und hinterlassen Sie Name, gewünschter Service und Wunschtermin. Ich melde mich umgehend!</p>

      {/* Button for saving the contact with the same style as "Termin Buchen" */}
      <a href="data:text/vcard;charset=utf-8,BEGIN:VCARD%0AVERSION:3.0%0AFN:Christine%20Wyss-Fischer%20KosmetikStudio%20Oberburg%0ATEL:+41797116662%0AEND:VCARD" download="Christine_Wyss-Fischer_KosmetikStudio_Oberburg.vcf" style={{ textDecoration: 'none', color: 'inherit' }}>
        <button className="button">Als Kontakt speichern</button>
      </a>



      {emailSent && <p>E-Mail wurde erfolgreich gesendet!</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div >
  );
}

export default Kontakt;