import React from 'react';
import NavBar from './NavBar';
import Studio from './Studio';
import About from './About';
import Angebot from './Angebot';
import Preise from './Preise';
import Kontakt from './Kontakt';
import './App.scss';

// Funktion zum manuellen Scrollen, um Safari-Probleme zu umgehen
const scrollElementIntoView = (element, behavior = 'auto') => {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

  // Höhe des Headers (falls vorhanden) wird berücksichtigt
  const headerHeight = document.querySelector('.header')?.clientHeight || 0;

  const finalOffset = element.getBoundingClientRect().top + scrollTop - headerHeight;

  window.scrollTo({
    top: finalOffset,
    behavior: behavior
  });
};

function App() {
  const images = ['/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg']; // Bilderreihe mit doppeltem Satz für nahtlose Animation

  // Funktion, um zum Kontakt-Abschnitt zu scrollen
  const scrollToContact = () => {
    const contactSection = document.getElementById('kontakt');
    if (contactSection) {
      scrollElementIntoView(contactSection, 'smooth');
    }
  };

  // Funktion, um zum Studio-Abschnitt zu scrollen
  const scrollToStudio = () => {
    const studioSection = document.getElementById('studio');
    if (studioSection) {
      scrollElementIntoView(studioSection, 'smooth');
    }
  };

  return (
    <div className="App">
      <meta name="description" content="Kosmetik Studio in Oberburg – Ihre Expertin für Schönheit und Wohlbefinden." />
      <meta name="keywords" content="Kosmetik, Schönheit, Wellness, Oberburg, Hautpflege, Studio" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Navigation Bar */}
      <NavBar />

      {/* Welcome Content with sliding images */}
      <div className="content" id="welcome">
        <div className="sliding-image-container">
          <div className="sliding-images">
            {images.map((image, index) => (
              <div
                key={index}
                className="image"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            ))}
          </div>
        </div>
        <div className="content-text">
          <h1>WILLKOMMEN</h1>
          <h2>
            Ihre Expertin<br></br>für Schönheit und Wohlbefinden<br></br>
            im Herzen von Oberburg
          </h2>
          <button className="button" onClick={scrollToContact} aria-label="Termin buchen">TERMIN BUCHEN</button>
          <div className="scroll-arrow" onClick={scrollToStudio} role="button" aria-label="Nach unten scrollen">
            &#x25BC;
          </div>
        </div>
      </div>

      {/* Other sections */}
      <section className="content" id="studio" aria-labelledby="studio-heading">
        <h2 id="studio-heading" hidden>Studio</h2>
        <Studio />
      </section>

      <section className="content" id="about" aria-labelledby="about-heading">
        <h2 id="about-heading" hidden>Über uns</h2>
        <About />
      </section>

      {/* <section className="content" id="angebot" aria-labelledby="angebot-heading">
        <h2 id="angebot-heading" hidden>Angebot</h2>
        <Angebot />
      </section> */}

      <section className="content" id="preise" aria-labelledby="preise-heading">
        <h2 id="preise-heading" hidden>Preise</h2>
        <Preise />
      </section>

      <section className="content" id="kontakt" aria-labelledby="kontakt-heading">
        <h2 id="kontakt-heading" hidden>Kontakt</h2>
        <Kontakt />
      </section>
    </div>
  );
}

export default App;
